var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: _vm.loading, "max-width": "500" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: dialog }) {
            return [
              !_vm.isIconDialog
                ? _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "font-weight-bold",
                        attrs: {
                          disabled: _vm.disabled,
                          text: "",
                          color: "secondary",
                        },
                      },
                      { ...dialog }
                    ),
                    [
                      _c("v-icon", [_vm._v("add")]),
                      _vm._v(" create new token "),
                    ],
                    1
                  )
                : _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on: tooltip }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    { attrs: { icon: "" } },
                                    { ...tooltip, ...dialog }
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v(_vm._s(_vm.activatorIcon)),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v("Edit")])]
                  ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _vm.dialogAction === _vm.dialogActions.CREATE_NEW_TOKEN
              ? _c(
                  "div",
                  { staticClass: "d-flex align-center secondary--text" },
                  [
                    _c("v-icon", { staticClass: "mr-1" }, [_vm._v("add")]),
                    _vm._v(" Create Token "),
                  ],
                  1
                )
              : _vm.dialogAction === _vm.dialogActions.EDIT_TOKEN
              ? _c(
                  "div",
                  { staticClass: "d-flex align-center secondary--text" },
                  [
                    _c("v-icon", { staticClass: "mr-1" }, [_vm._v("edit")]),
                    _vm._v(" Edit Token "),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("v-spacer"),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "font-weight-bold secondary--text mt-3 text-uppercase",
                        },
                        [_vm._v("select token scope")]
                      ),
                      _c("v-select", {
                        attrs: {
                          rules: _vm.newTokenFormRules,
                          items: Object.keys(_vm.orgTokenScopes),
                          label: "Token scope*",
                          required: "",
                          outlined: "",
                        },
                        model: {
                          value: _vm.newTokenScope,
                          callback: function ($$v) {
                            _vm.newTokenScope = $$v
                          },
                          expression: "newTokenScope",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "font-weight-bold secondary--text mt-3 text-uppercase",
                        },
                        [_vm._v("select token type")]
                      ),
                      _c("v-select", {
                        attrs: {
                          rules: _vm.newTokenFormRules,
                          items: Object.keys(_vm.orgTokenTypes),
                          label: "Token type*",
                          required: "",
                          outlined: "",
                        },
                        model: {
                          value: _vm.newTokenType,
                          callback: function ($$v) {
                            _vm.newTokenType = $$v
                          },
                          expression: "newTokenType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.newTokenScope &&
                  _vm.newTokenType &&
                  _vm.orgTokenTypes[_vm.newTokenType] ===
                    _vm.orgTokenTypes.XPRESSFEED
                    ? _c(
                        "div",
                        {
                          staticClass: "d-flex flex-column",
                          attrs: { outlined: "" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-bold secondary--text mt-3 text-uppercase",
                            },
                            [_vm._v("provide token details")]
                          ),
                          _c("v-text-field", {
                            attrs: {
                              autocomplete: "new-username",
                              rules: _vm.newTokenFormRules,
                              label: "Username*",
                              required: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.newTokenUsername,
                              callback: function ($$v) {
                                _vm.newTokenUsername = $$v
                              },
                              expression: "newTokenUsername",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              autocomplete: "new-password",
                              rules: _vm.newTokenFormRules,
                              type: _vm.showPassword ? "text" : "password",
                              "append-icon": _vm.showPassword
                                ? "visibility"
                                : "visibility_off",
                              label: "Password*",
                              required: "",
                              outlined: "",
                            },
                            on: {
                              "click:append": function ($event) {
                                _vm.showPassword = !_vm.showPassword
                              },
                            },
                            model: {
                              value: _vm.newTokenPassword,
                              callback: function ($$v) {
                                _vm.newTokenPassword = $$v
                              },
                              expression: "newTokenPassword",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.newTokenScope && _vm.newTokenType
                    ? _c(
                        "div",
                        [
                          _c("v-text-field", {
                            attrs: {
                              autocomplete: "new-token",
                              rules: _vm.newTokenFormRules,
                              type: _vm.showPassword ? "text" : "password",
                              "append-icon": _vm.showPassword
                                ? "visibility"
                                : "visibility_off",
                              label: "Token*",
                              required: "",
                              outlined: "",
                            },
                            on: {
                              "click:append": function ($event) {
                                _vm.showPassword = !_vm.showPassword
                              },
                            },
                            model: {
                              value: _vm.newTokenValue,
                              callback: function ($$v) {
                                _vm.newTokenValue = $$v
                              },
                              expression: "newTokenValue",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.loading,
                    color: "secondary",
                    text: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
              _vm.dialogAction === _vm.dialogActions.CREATE_NEW_TOKEN
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.loading,
                        disabled: !_vm.valid,
                        color: "primary",
                        text: "",
                      },
                      on: { click: _vm.createNewToken },
                    },
                    [_vm._v(" create ")]
                  )
                : _vm.dialogAction === _vm.dialogActions.EDIT_TOKEN
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.loading,
                        disabled: !_vm.valid,
                        color: "error",
                        text: "",
                      },
                      on: { click: _vm.editToken },
                    },
                    [_vm._v(" confirm ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }